<template>
  <div class="tecnica-component">
    <v-card flat class="mt-0" elevation="0">
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <Crud :title="'Metas técnicas'" buttonAddItemText="Nova meta" :tamanhoModalCrud="1000"
              searchLabelText="Pesquisar na lista" :formTitle="nameForm" :callBackModalCancelar="fechar"
              :dialog.sync="dialog" :callBackModalSalvar="salvar" :loadingButtonSave="loadingButtonSaveModal"
              :dialogDelete.sync="dialogDelete">
              <template slot="data-table">
                <v-data-table :headers="headers" :items="listaMetasTecnicas" :loading="isLoadingTable"
                  class="table-kitchen-sink" items-per-page-text="Linhas por página"
                  no-results-text="Nenhum registro encontrado" no-data-text="Nenhum registro encontrado"
                  loading-text="Carregando... Por favor, espere" :footer-props="{
                    showFirstLastPage: true,
                    'items-per-page-text': 'Linhas por página',
                  }" mobile-breakpoint="0">
                  <template v-slot:[`item.objetivo`]="{ item }">
                    <span>{{ item.objetivo | processFloat(item.tipo_unidade_medida) }}</span>
                  </template>
                  <template v-slot:[`item.tipo_tecnica`]="{ item }">
                    <v-chip :color="item.tipo_tecnica == 'E' ? 'warning' : 'info'">
                      {{ item.tipo_tecnica == 'E' ? 'Equipe'
                        : 'Individual' }}
                    </v-chip>
                  </template>
                  <!-- ACTIONS -->
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-menu bottom left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <!-- Editar -->
                        <v-list-item>
                          <v-list-item-title>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on" @click="editar(item)">
                                  <v-icon class="actions"> mdi-pencil </v-icon>
                                </v-btn>
                              </template>
                              <span>Editar</span>
                            </v-tooltip>
                          </v-list-item-title>
                        </v-list-item>

                        <!-- Remover -->
                        <v-list-item>
                          <v-list-item-title>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on" @click="remover(item, true)">
                                  <v-icon class="actions"> mdi-delete </v-icon>
                                </v-btn>
                              </template>
                              <span>Deletar</span>
                            </v-tooltip>
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </template>
                </v-data-table>
              </template>
              <!--------------- MODAL DE DELETE --------------------------------------------- -->
              <template slot="modalDelete/titleModal">
                Excluir Meta?
              </template>
              <template slot="modalDelete/contentModal">
                <p>
                  Atenção! Você está prestes a realizar uma ação que não pode ser
                  desfeita.
                </p>
                <p>Você realmente deseja excluir esse registro?</p>
              </template>
              <template slot="modalDelete/actionsModal">
                <v-btn depressed @click="dialogDelete = false">Fechar</v-btn>
                <v-btn :loading="loadingButtonDelete" color="error" @click="remover()">
                  Excluir
                </v-btn>
                <v-spacer class="d-none d-md-block"></v-spacer>
              </template>
              <!-- ------------- MODAL DE CADASTRO --------------------------------------------- -->
              <template slot="contentModalCrud">
                <v-row>
                  <v-col cols="12">
                    <v-tabs show-arrows center-active v-model="tab">
                      <v-tab v-for="(item, index) in tabsFormularioMetas" :disabled="item.disabled" :key="index"
                        :href="`#tab-${index}`" style="color: #5c6da7">
                        <v-icon size="20" class="me-3">{{ item.icon }}</v-icon>
                        <span> {{ item.nome }}</span>
                      </v-tab>
                      <v-tab-item v-for="(item, index) in tabsFormularioMetas" :key="index" :value="'tab-' + index">
                        <component ref="componentModel" :is="item.componente"></component>
                      </v-tab-item>
                    </v-tabs>
                  </v-col>
                </v-row>
              </template>
            </Crud>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import store from "@/store";
import Crud from "@/components/Crud.vue";
import Grid from "@/components/Grid.vue";
import { required, requiredNumeber } from "@core/utils/validation";
import { onMounted, ref, computed } from "@vue/composition-api";
import { unMaskNumber } from "@/helpers";

export default {
  name: "tecnica-Component",
  components: { Crud, Grid },
  inject: ['$validator'],
  setup(_, { emit }) {
    onMounted(() => {
      const validate = [['id_avaliacao', avaliacao.value.id]];
      const columns = ['id', 'uuid', 'id_avaliacao', 'verbo', 'objetivo', 'tipo_tecnica', 'tipo_unidade_medida'];

      const data = {
        columns,
        validate
      }

      store.dispatch('avdTecnicas/findByColumns', data).finally(() => {
        isLoadingTable.value = false;
      });
    });

    // Computed
    const tabsFormularioMetas = computed(
      () => store.getters["avdTecnicas/getTabsFormulario"]
    );
    const avaliacao = computed(() => store.getters["avd/getAvaliacao"]);
    const distribuicaoValores = computed(
      () => store.getters["avdTecnicas/getDistribuicaoValores"]
    );
    const configuracao = computed(
      () => store.getters["avdTecnicas/getConfiguracao"]
    );
    const configMetasIndividuais = computed({
      get() {
        return store.getters['avdTecnicas/getConfigMetasIndividuais']
      },
      set(value) {
        store.dispatch('avdTecnicas/setConfigMetasIndividuais', value)
      }
    });
    const listaMetasTecnicas = computed({
      get() {
        return store.getters['avdTecnicas/getConfiguracoes']
      },
      set(value) {
        store.dispatch('avdTecnicas/setConfiguracoes', value)
      }
    });

    // Variables
    const nameForm = ref("Nova meta");
    const headers = ref([
      { text: "Verbo", value: "verbo" },
      { text: "Objetivo", value: "objetivo" },
      { text: "Tipo da meta", value: "tipo_tecnica" },
      { text: "Ações", value: "actions", sortable: false },
    ]);
    const dialog = ref(false);
    const isLoadingTable = ref(true);
    const tab = ref();
    const currentTab = ref(0);
    const componentModel = ref(null);
    const loadingButtonSaveModal = ref(false);
    const dialogDelete = ref(false);
    const loadingButtonDelete = ref(false);
    let itemRemove = {};

    // Functions
    /**
     * @description Processa a lista de colaboradores para trazer somente o id
     */
    function processaListaColaboradores(isEdit) {
      let colaboradoresProcessados = [];

      configMetasIndividuais.value.colaboradoresSelected.forEach((el) => {
        const meta = processaMetas(el.meta);

        const objectUser = {
          id_colaborador: isEdit == -1 ? el.id : el.id_colaborador,
          meta: meta
        };

        colaboradoresProcessados.push(objectUser);
      });

      return colaboradoresProcessados;
    }

    /**
     * @description processa as meta e retorna padronizada para requisição.
    */
    function processaMetas(meta = Array) {
      if (!meta || meta.length < 1) return [];

      meta.forEach(el => {
        el.objetivo = unMaskNumber(el.objetivo, configuracao.value.unidade)
        delete el.mask;
        delete el.value
      });

      return meta
    }

    /**
     * @description Processa das distribuicao de valores para retornar somente o valor
     */
    function processaDistribuicaoValores() {
      let distribuicaoValoresProcessados = [];

      distribuicaoValores.value.forEach((el) => {
        distribuicaoValoresProcessados.push({
          value: unMaskNumber(el.value, configuracao.value.unidade),
        });
      });

      return distribuicaoValoresProcessados;
    }

    function salvar() {

      componentModel.value[0].validate().then(result => {
        if (result == true) {

          // Validar configuração da meta
          if (!validarConfiguracao) {
            store.dispatch("snackBarModule/openSnackBar", {
              color: "error",
              timeout: 3000,
              text: "A distribuição de valores precisam estar todas preenchidas.",
            });

            return false;
          }
          // Validar distribuição de valores
          if (!validarDistribuicaoValores) {
            store.dispatch("snackBarModule/openSnackBar", {
              color: "error",
              timeout: 3000,
              text: "Obrigatório haver distribuição de valores.",
            });

            return false;
          }
          // Validar meta individual
          if (
            configMetasIndividuais.value.colaboradoresSelected &&
            configMetasIndividuais.value.colaboradoresSelected.length < 1
          ) {
            store.dispatch("snackBarModule/openSnackBar", {
              color: "error",
              timeout: 3000,
              text: "Obrigatório haver um gestor selecionado em Meta Individual.",
            });

            return false;
          }

          const isEdit = configuracao.value.id != undefined || configuracao.value.uuid != undefined ? 1 : -1;
          loadingButtonSaveModal.value = true

          let url =
            isEdit === -1
              ? "/avd/configuracao"
              : `/avd/configuracao/${configuracao.value.uuid}`;
          let method = isEdit === -1 ? "POST" : "PUT";
          let sucesso =
            isEdit === -1
              ? "Meta cadastrado com sucesso."
              : "Meta atualizado com sucesso.";
          let erro =
            isEdit === -1
              ? "Erro ao salvar meta."
              : "Erro ao atualizar meta.";

          const data = {
            avaliacao: {
              id: avaliacao.value.id,
              periodicidade: avaliacao.value.periodicidade,
              data_inicio: avaliacao.value.data_hora_inicio,
              id_escala: 1 // NOTE O dia que haver mais escalas altera-las aqui.
            },
            configuracao: { ...configuracao.value },
            colaboradores: processaListaColaboradores(isEdit),
            distribuicao_valores: processaDistribuicaoValores(),
            _method: method
          };
          data.configuracao.objetivo = unMaskNumber(
            data.configuracao.objetivo,
            configuracao.value.unidade
          );

          let configuracaoItem = {
            data: data,
            url: url
          };

          store
            .dispatch("avdTecnicas/save", configuracaoItem)
            .then((response) => {
              if (response != "NAO_AUTORIZADO") {
                if (isEdit == -1) {
                  listaMetasTecnicas.value.push(response);
                } else {
                  const index = listaMetasTecnicas.value.findIndex(
                    (el) => el.id == configuracao.value.id
                  );

                  Object.assign(listaMetasTecnicas.value[index], configuracao.value);
                }
                store.dispatch("snackBarModule/openSnackBar", {
                  color: "success",
                  timeout: 3000,
                  text: sucesso,
                });
              }
            })
            .catch(() => {
              store.dispatch("snackBarModule/openSnackBar", {
                color: "danger",
                timeout: 3000,
                text: erro,
              });
            })
            .finally(() => {
              loadingButtonSaveModal.value = false;
              fechar();
            });

        }
      })
    };
    function editar(item) {
      isLoadingTable.value = true

      store.dispatch("avdTecnicas/findByUuid", item.uuid).then(() => {
        dialog.value = true;
      }).catch(() => {
        store.dispatch("snackBarModule/openSnackBar", {
          color: "error",
          timeout: 5000,
          text: "Ocorreu um erro em buscar pelos dados da meta. Por favor, tente novamente mais tarde.",
        });
      }).finally(() => {
        isLoadingTable.value = false
      })
    };
    function fechar() {
      store.dispatch('avdTecnicas/resetDatas');
      store.dispatch('avdTecnicas/calculaDadosDistribuicaoDeValores');
      dialog.value = false;
      tab.value = 'tab-0';

      tabsFormularioMetas.value[1].disabled = true
      tabsFormularioMetas.value[2].disabled = true
    };
    function validateFormNewMeta() {
      return (dialog.value = false);
    };
    function salvarConfiguracao() {
      modalLateral.value.title = modalLateral.value.show
        ? ""
        : "Configuração geral";
      modalLateral.value.item = modalLateral.value.show ? [] : item;
      modalLateral.value.show = modalLateral.value.show ? false : true;
    }
    function validarConfiguracao() {
      return configuracao.value.tipo !== null &&
        configuracao.value.unidade !== null &&
        configuracao.value.melhor_se !== null &&
        configuracao.value.verbo !== null &&
        configuracao.value.complemento !== null &&
        configuracao.value.objetivo !== null
        ? true
        : false;
    };
    function validarDistribuicaoValores() {
      // let objetivoConvertido = unMaskNumber(configuracao.value.objetivo, configuracao.value.unidade);

      // if (distribuicaoValores.value.length === 0) return false;
      // if (objetivoConvertido >= totalApurado.value) return true;

      let validador = distribuicaoValores.value.find(
        (f) => f.value == 0 || f.value == ""
      );

      return validador === undefined ? true : false;
    };
    function validate() {
      return true;
    }
    function remover(item, openModal = false) {
      if (openModal) {
        itemRemove.value = Object.assign({}, item);
        dialogDelete.value = true;
      } else {
        loadingButtonDelete.value = true;
        const indexItem = listaMetasTecnicas.value.findIndex((el) => el.uuid == itemRemove.value.uuid);

        this.$store
          .dispatch("avdTecnicas/remove", itemRemove.value.uuid)
          .then((response) => {
            if (response.data === true) {
              listaMetasTecnicas.value.splice(indexItem, 1);

              this.$store.dispatch("snackBarModule/openSnackBar", {
                color: "success",
                timeout: 3000,
                text: "meta removida com sucesso.",
              });
            }
          })
          .catch(() => {
            this.$store.dispatch("snackBarModule/openSnackBar", {
              color: "error",
              timeout: 3000,
              text: "Não foi possivel remover a meta.",
            });
          })
          .finally(() => {
            loadingButtonDelete.value = false;
            dialogDelete.value = false;
            itemRemove.value = {};
          });
      }
    };

    return {
      validators: {
        required,
        requiredNumeber,
      },
      //Functions
      salvar,
      editar,
      fechar,
      validateFormNewMeta,
      salvarConfiguracao,
      validate,
      remover,

      //Variaveis
      isLoadingTable,
      dialog,
      nameForm,
      headers,
      currentTab,
      tab,
      tabsFormularioMetas,
      componentModel,
      loadingButtonSaveModal,
      dialogDelete,
      loadingButtonDelete,

      // Computed
      listaMetasTecnicas,
    };
  },
};
</script>

<style lang="scss" scoped></style>
