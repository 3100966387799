import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tecnica-component"},[_c(VCard,{staticClass:"mt-0",attrs:{"flat":"","elevation":"0"}},[_c(VCardText,[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('Crud',{attrs:{"title":'Metas técnicas',"buttonAddItemText":"Nova meta","tamanhoModalCrud":1000,"searchLabelText":"Pesquisar na lista","formTitle":_vm.nameForm,"callBackModalCancelar":_vm.fechar,"dialog":_vm.dialog,"callBackModalSalvar":_vm.salvar,"loadingButtonSave":_vm.loadingButtonSaveModal,"dialogDelete":_vm.dialogDelete},on:{"update:dialog":function($event){_vm.dialog=$event},"update:dialogDelete":function($event){_vm.dialogDelete=$event},"update:dialog-delete":function($event){_vm.dialogDelete=$event}}},[_c('template',{slot:"data-table"},[_c(VDataTable,{staticClass:"table-kitchen-sink",attrs:{"headers":_vm.headers,"items":_vm.listaMetasTecnicas,"loading":_vm.isLoadingTable,"items-per-page-text":"Linhas por página","no-results-text":"Nenhum registro encontrado","no-data-text":"Nenhum registro encontrado","loading-text":"Carregando... Por favor, espere","footer-props":{
                  showFirstLastPage: true,
                  'items-per-page-text': 'Linhas por página',
                },"mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"item.objetivo",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("processFloat")(item.objetivo,item.tipo_unidade_medida)))])]}},{key:"item.tipo_tecnica",fn:function(ref){
                var item = ref.item;
return [_c(VChip,{attrs:{"color":item.tipo_tecnica == 'E' ? 'warning' : 'info'}},[_vm._v(" "+_vm._s(item.tipo_tecnica == 'E' ? 'Equipe' : 'Individual')+" ")])]}},{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [_c(VMenu,{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c(VList,[_c(VListItem,[_c(VListItemTitle,[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.editar(item)}}},'v-btn',attrs,false),on),[_c(VIcon,{staticClass:"actions"},[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])])],1)],1),_c(VListItem,[_c(VListItemTitle,[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.remover(item, true)}}},'v-btn',attrs,false),on),[_c(VIcon,{staticClass:"actions"},[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v("Deletar")])])],1)],1)],1)],1)]}}],null,true)})],1),_c('template',{slot:"modalDelete/titleModal"},[_vm._v(" Excluir Meta? ")]),_c('template',{slot:"modalDelete/contentModal"},[_c('p',[_vm._v(" Atenção! Você está prestes a realizar uma ação que não pode ser desfeita. ")]),_c('p',[_vm._v("Você realmente deseja excluir esse registro?")])]),_c('template',{slot:"modalDelete/actionsModal"},[_c(VBtn,{attrs:{"depressed":""},on:{"click":function($event){_vm.dialogDelete = false}}},[_vm._v("Fechar")]),_c(VBtn,{attrs:{"loading":_vm.loadingButtonDelete,"color":"error"},on:{"click":function($event){return _vm.remover()}}},[_vm._v(" Excluir ")]),_c(VSpacer,{staticClass:"d-none d-md-block"})],1),_c('template',{slot:"contentModalCrud"},[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VTabs,{attrs:{"show-arrows":"","center-active":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_vm._l((_vm.tabsFormularioMetas),function(item,index){return _c(VTab,{key:index,staticStyle:{"color":"#5c6da7"},attrs:{"disabled":item.disabled,"href":("#tab-" + index)}},[_c(VIcon,{staticClass:"me-3",attrs:{"size":"20"}},[_vm._v(_vm._s(item.icon))]),_c('span',[_vm._v(" "+_vm._s(item.nome))])],1)}),_vm._l((_vm.tabsFormularioMetas),function(item,index){return _c(VTabItem,{key:index,attrs:{"value":'tab-' + index}},[_c(item.componente,{ref:"componentModel",refInFor:true,tag:"component"})],1)})],2)],1)],1)],1)],2)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }